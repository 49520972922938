import { Link, navigate, routes } from '@redwoodjs/router'
import { IconButton, Collapse, Box, useMediaQuery } from '@chakra-ui/react'
import { FiFolder, FiChevronRight, FiChevronDown } from 'react-icons/fi'
import {
  FiPlus,
  FiRepeat,
  FiFileText,
  FiUsers,
  FiTool,
  FiDollarSign,
  FiSettings,
  FiHome,
} from 'react-icons/fi'
import SidebarButton from './SidebarButton'
import { useState, useEffect } from 'react'
import { useQuery } from '@redwoodjs/web'
const GET_ORGANIZATION_CODE = gql`
  query GetOrganizationCode {
    organization: organizationFromAuth {
      code
      metadata
    }
  }
`

const Sidebar = () => {
  const { data: orgData } = useQuery(GET_ORGANIZATION_CODE)
  const [collapsed, setCollapsed] = useState(false)
  const [isLocalhost, setIsLocalhost] = useState(false)
  const [isSmallScreen] = useMediaQuery('(max-width: 1300px)')
  const [openSection, setOpenSection] = useState<
    'manage' | 'setup' | 'tools' | null
  >(null)

  useEffect(() => {
    setIsLocalhost(window.location.hostname === 'localhost')
  }, [])

  const sidebarColor = isLocalhost ? 'bg-gray-800' : 'bg-xweave'

  const handleSectionToggle = (section: 'manage' | 'setup' | 'tools') => {
    setOpenSection(openSection === section ? null : section)
  }
  return (
    <div>
      <div
        className={`fixed flex h-screen flex-col border-r-2 border-gray-200 shadow-md dark:border-gray-700 dark:text-white ${sidebarColor} ${
          collapsed ? 'w-20' : 'w-64'
        }`}
      >
        <header className="flex flex-grow-0 flex-col">
          <div
            className="mb-4 mt-6 self-center"
            onClick={() => {
              setCollapsed(!collapsed)
            }}
          >
            <img
              className={collapsed ? 'mb-2 mt-2 h-6 w-6' : 'hidden'}
              src="logos/xweave_logo_flat.png"
              alt="Xweave Logo"
            />
            <img
              className={collapsed ? 'hidden' : 'mb-2 mt-2 w-52 pl-2 pr-2'}
              src="xweave_w_logo.png"
              alt="Xweave Logo"
            />
          </div>
        </header>

        {collapsed ? (
          <>
            <div className="flex w-full flex-col items-center gap-4">
              im
              <IconButton
                className="mx-2"
                aria-label="Getting Started"
                size="sm"
                icon={<FiHome />}
                onClick={() => {
                  navigate(routes.setup())
                }}
              />
              <IconButton
                className="mx-2"
                size="sm"
                aria-label="Transactions"
                icon={<FiDollarSign />}
                onClick={() => {
                  navigate(routes.quotes())
                }}
              />
              <IconButton
                className="mx-2"
                aria-label="Invoices"
                size="sm"
                icon={<FiFileText />}
                onClick={() => {
                  navigate(routes.invoices())
                }}
              />
              <div className="flex flex-grow-[0.5] gap-2"></div>
              <IconButton
                className="mx-2"
                aria-label="Integrations"
                size="sm"
                icon={<FiPlus />}
                onClick={() => {
                  navigate(routes.integrations())
                }}
              />
              <IconButton
                className="mx-2"
                aria-label="Developer Tools"
                size="sm"
                icon={<FiTool />}
                onClick={() => {
                  navigate(routes.developer_tools())
                }}
              />
              <div className="flex flex-grow-[0.5]"></div>
              <IconButton
                className="mx-2"
                aria-label="Swap"
                size="sm"
                icon={<FiRepeat />}
                onClick={() => {
                  navigate(routes.swap())
                }}
              />
              <IconButton
                className="mx-2"
                aria-label="Bundle"
                size="sm"
                icon={<FiFolder />}
                onClick={() => {
                  navigate(routes.bundle())
                }}
              />
              <div className="flex flex-grow-[0.5]"></div>
              <IconButton
                className="mx-2"
                aria-label="Organization"
                size="sm"
                icon={<FiUsers />}
                onClick={() => {
                  navigate(routes.organization())
                }}
              />
              <IconButton
                className="mx-2"
                aria-label="Settings"
                size="sm"
                icon={<FiSettings />}
                onClick={() => {
                  navigate(routes.settings())
                }}
              />
            </div>
          </>
        ) : isSmallScreen ? (
          <>
            <div className="flex flex-grow-[0.5]"></div>
            <Box className="mx-8 flex flex-grow flex-col gap-2">
              <div className="flex items-center justify-between">
                <p className="text-[12px] text-gray-300">MANAGE</p>
                <IconButton
                  size="xs"
                  icon={
                    openSection === 'manage' ? (
                      <FiChevronDown />
                    ) : (
                      <FiChevronRight />
                    )
                  }
                  aria-label="Open Manage"
                  onClick={() => handleSectionToggle('manage')}
                />
              </div>
              <Collapse in={openSection === 'manage'} animateOpacity>
                <div className="ml-4">
                  <SidebarButton match="/home">
                    <IconButton
                      className="mx-1"
                      aria-label="Getting Started"
                      size="xs"
                      icon={<FiHome />}
                    />
                    <Link
                      className="ml-4 text-left text-sm"
                      to={routes.setup()}
                    >
                      Getting Started
                    </Link>
                  </SidebarButton>
                  <SidebarButton match="/quotes">
                    <IconButton
                      className="mx-1"
                      size="xs"
                      aria-label="Transactions"
                      icon={<FiDollarSign />}
                    />
                    <Link
                      className="ml-4 text-left text-sm"
                      to={routes.quotes()}
                    >
                      Transactions
                    </Link>
                  </SidebarButton>
                  <SidebarButton match="/invoices">
                    <IconButton
                      className="mx-1"
                      aria-label="Invoices"
                      size="xs"
                      icon={<FiFileText />}
                    />
                    <Link
                      className="ml-4 text-left text-sm"
                      to={routes.invoices()}
                    >
                      Invoices
                    </Link>
                  </SidebarButton>
                </div>
              </Collapse>
            </Box>
            <Box className="mx-8 mt-4 flex flex-grow flex-col gap-2">
              <div className="flex items-center justify-between">
                <p className="text-[12px] text-gray-300">SETUP</p>
                <IconButton
                  size="xs"
                  icon={
                    openSection === 'setup' ? (
                      <FiChevronDown />
                    ) : (
                      <FiChevronRight />
                    )
                  }
                  aria-label="Open Setup"
                  onClick={() => handleSectionToggle('setup')}
                />
              </div>
              <Collapse in={openSection === 'setup'} animateOpacity>
                <div className="ml-4">
                  <SidebarButton match="/integrations">
                    <IconButton
                      className="mx-1"
                      aria-label="Integrations"
                      size="xs"
                      icon={<FiPlus />}
                    />
                    <Link
                      className="ml-4 text-left text-sm"
                      to={routes.integrations()}
                    >
                      Integrations
                    </Link>
                  </SidebarButton>
                  <SidebarButton match="/developer-tools">
                    <IconButton
                      className="mx-1"
                      aria-label="Developer Tools"
                      size="xs"
                      icon={<FiTool />}
                    />
                    <Link
                      className="ml-4 text-left text-sm"
                      to={routes.developer_tools()}
                    >
                      Developer Tools
                    </Link>
                  </SidebarButton>
                </div>
              </Collapse>
            </Box>
            <Box className="mx-8 mt-4 flex flex-grow flex-col gap-2">
              <div className="flex items-center justify-between">
                <p className="text-[12px] text-gray-300">TOOLS</p>
                <IconButton
                  size="xs"
                  icon={
                    openSection === 'tools' ? (
                      <FiChevronDown />
                    ) : (
                      <FiChevronRight />
                    )
                  }
                  aria-label="Open Tools"
                  onClick={() => handleSectionToggle('tools')}
                />
              </div>
              <Collapse in={openSection === 'tools'} animateOpacity>
                <div className="ml-4">
                  <SidebarButton match="/swap">
                    <IconButton
                      className="mx-1"
                      aria-label="Swap"
                      size="xs"
                      icon={<FiRepeat />}
                    />
                    <Link className="ml-4 text-left text-sm" to={routes.swap()}>
                      Swap
                    </Link>
                  </SidebarButton>
                  <SidebarButton match="/bundle">
                    <IconButton
                      className="mx-1"
                      aria-label="Bundle"
                      size="xs"
                      icon={<FiFolder />}
                    />
                    <Link
                      className="ml-4 text-left text-sm"
                      to={routes.bundle()}
                    >
                      Bundle
                    </Link>
                  </SidebarButton>
                </div>
              </Collapse>
            </Box>
            <div className="flex flex-grow-[10]"></div>

            <footer className="mx-8 mb-8 flex flex-grow-[0.3] flex-col gap-2 text-xl">
              <SidebarButton match="/organization">
                <IconButton
                  className="mx-1"
                  aria-label="Organization"
                  size="xs"
                  icon={<FiUsers />}
                />
                <Link
                  className="ml-4 text-left text-sm"
                  to={routes.organization()}
                >
                  Organization
                </Link>
              </SidebarButton>
              <SidebarButton match="/settings">
                <IconButton
                  className="mx-1"
                  aria-label="Settings"
                  size="xs"
                  icon={<FiSettings />}
                />
                <Link className="ml-5 text-left text-sm" to={routes.settings()}>
                  Settings
                </Link>
              </SidebarButton>
            </footer>
          </>
        ) : (
          <>
            <div className="flex flex-grow-[0.5]"></div>
            <div className="mx-8 flex flex-grow flex-col gap-2">
              <p className="text-[12px] text-gray-300">MANAGE</p>
              <SidebarButton match="/home">
                <IconButton
                  className="mx-1"
                  aria-label="Getting Started"
                  size="xs"
                  icon={<FiHome />}
                />
                <Link className="ml-4 text-left text-sm" to={routes.setup()}>
                  Getting Started
                </Link>
              </SidebarButton>
              <SidebarButton match="/quotes">
                <IconButton
                  className="mx-1"
                  size="xs"
                  aria-label="Transactions"
                  icon={<FiDollarSign />}
                />
                <Link className="ml-4 text-left text-sm" to={routes.quotes()}>
                  Transactions
                </Link>
              </SidebarButton>
              <SidebarButton match="/invoices">
                <IconButton
                  className="mx-1"
                  aria-label="Invoices"
                  size="xs"
                  icon={<FiFileText />}
                />
                <Link className="ml-4 text-left text-sm" to={routes.invoices()}>
                  Invoices
                </Link>
              </SidebarButton>
            </div>
            <div className="mx-8 mt-4 flex flex-grow flex-col gap-2">
              <p className="text-[12px] text-gray-300">SETUP</p>
              <SidebarButton match="/integrations">
                <IconButton
                  className="mx-1"
                  aria-label="Integrations"
                  size="xs"
                  icon={<FiPlus />}
                />
                <Link
                  className="ml-4 text-left text-sm"
                  to={routes.integrations()}
                >
                  Integrations
                </Link>
              </SidebarButton>
              <SidebarButton match="/developer-tools">
                <IconButton
                  className="mx-1"
                  aria-label="Developer Tools"
                  size="xs"
                  icon={<FiTool />}
                />
                <Link
                  className="ml-4 text-left text-sm"
                  to={routes.developer_tools()}
                >
                  Developer Tools
                </Link>
              </SidebarButton>
            </div>
            <div className="mx-8 mt-4 flex flex-grow flex-col gap-2">
              <p className="text-[12px] text-gray-300">TOOLS</p>
              <SidebarButton match="/swap">
                <IconButton
                  className="mx-1"
                  aria-label="Swap"
                  size="xs"
                  icon={<FiRepeat />}
                />
                <Link className="ml-4 text-left text-sm" to={routes.swap()}>
                  Swap
                </Link>
              </SidebarButton>
              <SidebarButton match="/bundle">
                <IconButton
                  className="mx-1"
                  aria-label="Bundle"
                  size="xs"
                  icon={<FiFolder />}
                />
                <Link className="ml-4 text-left text-sm" to={routes.bundle()}>
                  Bundle
                </Link>
              </SidebarButton>
            </div>
            <div className="flex flex-grow-[10]"></div>

            <footer className="mx-8 mb-4 flex flex-grow-[0.3] flex-col gap-2">
              <SidebarButton match="/organization">
                <IconButton
                  className="mx-1"
                  aria-label="Organization"
                  size="xs"
                  icon={
                    orgData ? (
                      <img
                        src={orgData?.organization?.metadata?.icon}
                        className="h-6 w-6"
                      />
                    ) : (
                      <FiUsers />
                    )
                  }
                />
                <Link
                  className="ml-4 text-left text-sm"
                  to={routes.organization()}
                >
                  Organization
                </Link>
              </SidebarButton>
              <SidebarButton match="/settings">
                <IconButton
                  className="mx-1"
                  aria-label="Settings"
                  size="xs"
                  icon={<FiSettings />}
                />
                <Link className="ml-5 text-left text-sm" to={routes.settings()}>
                  Settings
                </Link>
              </SidebarButton>
            </footer>
          </>
        )}
      </div>
      <div
        className={`ml-2 hidden bg-slate-600 lg:block ${
          collapsed ? 'w-16' : 'w-60'
        }`}
      ></div>
    </div>
  )
}

export default Sidebar
